/** @format */

.gallery-filter-component-layout {
  display: flex;
  gap: 25px;
  margin-top: 30px;
  margin-left: 15px;
  justify-content: space-between;
}
.gallery-filter-component-visible-options {
  display: flex;
  gap: 25px;
}
.gallery-filter-component-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  max-width: fit-content;
  height: 34px;
  flex-shrink: 0;
  border-radius: 90px;
  border: 1px solid #d4dfff;
  background: #e9e7fd;
  color: #1206f9;
  font-family: 'Poppins-SemiBold', Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.gallery-filter-component-unselected {
  border-radius: 30px;
  border: 1px solid var(--text-field-bg-stroke, #d1d1d1);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  max-width: fit-content;
  height: 34px;
  flex-shrink: 0;
  cursor: pointer;
  color: rgb(115, 113, 113);
  font-family: 'Poppins-SemiBold', Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding: 10px;
  transition: all 0.2s ease;
}

.gallery-filter-component-unselected:hover {
  background: #f5f5f5;
  border-color: #bcbcbc;
  color: #000;
}

.gallery-filter-component-selected:active {
  background: #d9d7fd;
  border-color: #aaaaff;
  color: #000;
  transform: scale(0.95);
}

.gallery-filter-component-video-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-evenly;
}
