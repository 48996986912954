/** @format */

.LOGIN {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;

  height: 100vh;
}

.LOGIN .overlap-wrapper {
  border: 1px none;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.LOGIN .overlap {
  height: 986px;
  left: -30px;
  position: relative;
  top: 11px;
  width: 1590px;
}

.LOGIN .ellipse {
  background: linear-gradient(
    180deg,
    rgb(207.75, 207.75, 251.81) 0%,
    rgba(230, 230, 255, 0) 100%
  );
  border-radius: 1px;
  height: 2px;
  left: 749px;
  position: absolute;
  top: 500px;
  width: 2px;
}

.LOGIN .image {
  height: 801px;
  left: 30px;
  object-fit: cover;
  position: absolute;
  top: 9px;
  width: 100%;
}

.LOGIN .group {
  background-color: #fafaff;
  height: 970px;
  left: 30px;
  position: absolute;
  top: 0;
  width: 100%;
}

.LOGIN .overlap-group-wrapper {
  height: 651px;
  left: 0;
  position: absolute;
  top: 105px;
  width: 1590px;
}

.LOGIN .overlap-group {
  height: 651px;
  left: 30px;
  position: relative;
  width: 100%;
}

.LOGIN .mask-group {
  background-image: url(../../../public/LoginImg/mask-group.png);
  background-size: 100% 100%;
  height: 325px;
  left: 186px;
  position: absolute;
  top: 56px;
  width: 534px;
}

.LOGIN .mirro-ribbon {
  height: 277px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 374px;
  width: 892px;
}

.LOGIN .img {
  height: 209px;
  left: 864px;
  object-fit: cover;
  position: absolute;
  top: 393px;
  width: 576px;
}

.LOGIN .div-wrapper {
  height: 573px;
  left: 800px;
  position: absolute;
  top: 0;
  width: 515px;
}

.LOGIN .div {
  height: 575px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 517px;
}

.LOGIN .rectangle {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #d4d4d4;
  border-radius: 6px;
  box-shadow: 4px 5px 6px #0000000f;
  height: 517px;
  left: -29px;
  position: absolute;
  top: 29px;
  transform: rotate(90deg);
  width: 575px;
}

.LOGIN .group-wrapper {
  height: 369px;
  left: 66px;
  position: absolute;
  top: 96px;
  width: 361px;
}

.LOGIN .group-2 {
  height: 369px;
  position: relative;
  width: 367px;
}

.LOGIN .group-3 {
  height: 51px;
  left: 6px;
  position: absolute;
  top: 252px;
  width: 357px;
}

.LOGIN .overlap-group-2 {
  border-radius: 80px;
  height: 51px;
  position: relative;
  width: 355px;
}

.LOGIN .rectangle-2 {
  background-color: #1206f9;
  border-radius: 80px;
  height: 51px;
  left: 0;
  position: absolute;
  top: 0;
  width: 355px;
}

.LOGIN .text-wrapper-2 {
  color: #ffffff;
  font-family: 'Poppins-Medium', Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 156px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  text-align: center;
  top: 15px;
  white-space: nowrap;
}

.LOGIN .group-4 {
  height: 55px;
  left: 0;
  position: absolute;
  top: 59px;
  width: 363px;
}

.LOGIN .overlap-2 {
  height: 55px;
  position: relative;
  width: 361px;
}
.Email-input{
  width:70%;
  height: 55px;
 
 width: 93%;
  padding: 10%;
}

.LOGIN .rectangle-3 {
  border: 1px solid;
  border-color: #a6a6a6;
  border-radius: 45px;
  height: 46px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 361px;
}

.LOGIN .label-instance {
  left: 12px !important;
  position: absolute !important;
  top: 0 !important;
}

.LOGIN .design-component-instance-node {
  color: #707070 !important;
  font-family: 'Poppins', Helvetica !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  white-space: unset !important;
}

.LOGIN .input-text {
  color: #9e9e9e;
  font-family: 'Poppins', Helvetica;
  font-size: 13px;
  font-weight: 400;
  left: 16px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 23px;
}

.LOGIN .group-5 {
  height: 55px;
  left: 0;
  position: absolute;
  top: 140px;
  width: 363px;
}

.LOGIN .text-wrapper-3 {
  color: #33344b;
  font-family: 'Poppins-SemiBold', Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 6px;
  letter-spacing: 0;
  line-height: 23px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.LOGIN .new-to-mirro-ai-sign {
  color: transparent;
  font-family: 'Poppins', Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 87px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  text-align: center;
  top: 407px;
  white-space: nowrap;
}

.LOGIN .span {
  color: #000000;
}

.LOGIN .text-wrapper-4 {
  color: #1206f9;
  font-family: 'Poppins-SemiBold', Helvetica;
  font-weight: 600;
  cursor: pointer;
}

.LOGIN .text-wrapper-5 {
  color: #1206f9;
  font-family: 'Poppins', Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 239px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  text-align: right;
  top: 205px;
  white-space: nowrap;
  cursor: pointer;
}

.LOGIN .group-6 {
  height: 339px;
  left: 216px;
  position: absolute;
  top: 161px;
  width: 540px;
}

.LOGIN .convocoach-our-AI {
  color: var(--body-text);
  font-family: 'Poppins-Medium', Helvetica;
  font-size: 33px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 40px;
  position: absolute;
  top: 158px;
  width: 534px;
}

.LOGIN .mirro-ai-logo {
  height: 18px;
  left: 79px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 111px;
}

.LOGIN .p {
  color: var(--body-text);
  font-family: 'Poppins', Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 261px;
  width: 489px;
}

.LOGIN .text-wrapper-6 {
  color: var(--body-text);
  font-family: 'Poppins', Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.LOGIN .image-2 {
  height: 78px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 61px;
  width: 106px;
}

.LOGIN .group-7 {
  height: 51px;
  left: 30px;
  position: absolute;
  top: 740px;
  width: 1438px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #9b9b9b;
  font-family: 'Poppins', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  background-color: #fafafa;
  border: 1px solid lightblue;
}

.LOGIN .element-mirro-ai-all-wrapper {
  background-color: #f9f9f9;
  border: 1px solid;
  border-color: #d4dfff;
  height: 51px;
  position: relative;
  width: 100%;
}
.terms-policies {
  display: flex;
  gap: 20px;
  color: #9b9b9b;
  font-family: 'Poppins', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}

.LOGIN .element-mirro-ai-all {
  color: #9b9b9b;
  font-family: 'Poppins', Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 32px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 14px;
  white-space: nowrap;
  width: 1312px;
}

.LOGIN .text-wrapper-7 {
  color: #9b9b9b;
  font-family: 'Poppins', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
}

.error-message {
  color: #df1413;
  font-size: 0.75rem;
}

.overlap-group-2-microsoftSSO {
  border-radius: 80px;
  height: 51px;
  width: 355px;
}

.group-microsoftSSO {
  height: 51px;
  left: 6px;
  position: absolute;
  top: 350px;
  width: 357px;
  text-transform: capitalize;
}

.group-login-with-sso {
  left: 6px;
  position: absolute;
  top: 320px;
  width: 357px;
  text-align: center;
  font-family: 'Poppins-SemiBold', Helvetica;
  font-size: 16px;
  font-weight: 500;
  color: #757575;
}

/** @format */

.sign-in-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #d4d4d4;
  border-radius: 6px;
  box-shadow: -4px 5px 6px #0000000f;
  position: absolute;
}

.create-an-acount {
  color: #33344b;
  font-family: 'Poppins-SemiBold', Helvetica;
  font-size: 16px;
  font-weight: 700;

  letter-spacing: 0;
  line-height: 23px;
  margin-bottom: 16px;
  white-space: nowrap;
}

.sign-in-layout-child {
  height: 90%;
  display: flex;
  flex-direction: column;
  margin: 30px;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
