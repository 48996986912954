/**
 * CSS
 *
 * @format
 */

.footer-toolbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* color: #9b9b9b; */
  color: #555;
  font-family: 'Poppins-SemiBold', Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6; /* Adjusted for better readability */
  padding: 10px;
}

.footer-toolbar-right-panel {
  display: flex;
  gap: 20px;
}

.footer-toolbar-mobile {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #555;
  font-family: 'Poppins-SemiBold', Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6; /* Adjusted for better readability */
  gap: 5px;
}

.footer-toolbar-right-panel-mobile {
  display: flex;
  gap: 3px;
  margin-bottom: 6px;
}
.footer-toolbar-menu {
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  padding: 10px;
  cursor: pointer;
}
