/** @format */

.cascade-container {
  height: 650px;
  overflow: scroll;
  padding: 5px;
}
.rotate-icon {
  transform: rotate(-90deg);
}

.card-container {
  position: relative;
  display: flex;
  align-items: center;
  margin: 20px;
}

.dot {
  width: 10px;
  height: 10px;

  border-radius: 50%;
  position: absolute;
  left: -3px; /* Adjust as needed */
  top: 0%;
  transform: translateY(-50%);
}
.dot-even {
  background-color: #00d8a5;
}

.dot-odd {
  background-color: #7f78ff;
}

.connecting-line {
  position: absolute;
  left: 0; /* Adjust as needed */
  width: 2px;
  background-color: #c7c5c5;
  top: 0;
  height: 100%;
}

/* Rest of your existing styles */

/* Rest of your existing styles */
.tipsPlayer {
  width: 80%;
  height: 150px;
  border-radius: 20px;
}
.tipsText {
  color: #2c2c2c;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.154px;
  margin-left: 5%;
}
.sourceNum {
  justify-content: flex-end; /* Align content to the right */
  align-items: center; /* Center vertically */
  color: #2c2c2c;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.132px;
  margin-left: auto;
  margin-right: 5%;
}
