/* videoplayer.css */
.barout {
  top: 0;
  width: 100%;
  height: fit-content;
  background: transparent;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
  display: block; /* Use flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  gap:5%;
}
.seeker-bar {
  /* Remove margin-top: 20px; */
  position: relative;
  width: 100%;
  height: 10px;
  cursor: pointer;
  padding: 10px;
}


.time-segment {
  position: absolute;
  height: 10px;
  top: 0;
  
}

/* ... (other CSS rules) */

.progress {
  height: 10px;
  background-color: transparent;
  position: relative;
  --progress: 0; /* Initialize progress to 0% */
}


.progress-dot {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  position:relative;
  cursor: pointer;
  
}



.progress-dot:hover {
  transition: left 0.2s ease-in-out; /* Apply the same transition on hover */
}


/* Add a hover effect to the progress bar */
.progress:hover .progress-dot {
  background-color: #00aaff;
}

.speakerDropDown {
width: 200px;
height: 25px;
margin-left: auto;
background-color: silver;
}
.flagsDropDown{
  width: 200px;
height: 25px;
margin-left: auto;
background-color: silver

}
.emojiDiv {
  position: absolute;
  top: -25px;
  left: 50%; /* Center the emoji horizontally */
  transform: translateX(-50%);
  z-index: 0; /* Set a lower z-index value */
}


.VideoControlsDiv{
  margin-right: auto;
  
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  gap:10px;
  
  
}
.pauseImg{
  width: 32px;
  height: 32px;
}
.PrevNextDiv {
  display: flex;
  gap: 30px;
 
}
.infoDiv {

text-align: center;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 0.19px;
text-transform: uppercase;
display: flex;
gap: 10px;
}
.flaginfo {
  display: inline-flex;
  
}
.videoPlayer {
   margin-top: 20px;
}
.emoticon-battery-container {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.65);
  height: fit-content;
  bottom: 0; /* Place it at the bottom */
  right: 2;
  top:0; /* Place it at the right */
  
  align-items: center;
  padding: 10px;
  z-index: 2; /* Ensure it appears above the video */
  
 
}
.emoticonDiv {
  width: fit-content;
  height: fit-content;
  display: block;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.batteryDiv {

  width: fit-content;
  height: fit-content;
  display: block;
  align-items: center;
  text-align: center;
  justify-content: center;

}




.emotionImage {
  height: 60px;
  width: 60px;
  padding: 5px;
  margin: 0; /* Reset margin to center the image */
}

.batteryImg {
  height: 60px;
  width: 30px;
  padding: 5px;
  margin: 0; /* Reset margin to center the image */
}


.emoticons{
width:20px;
height: 20px;
}
.indivisualSummary {
  padding: 5%;
  width: 90%;
  height: auto;
  display: flex;
  gap: 80px; 
  
  align-items: center;
  justify-content: center;

}

.centeredContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.summaryEmoji {
  width: 45px;
  height: 45px;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .emoticon-battery-container {
    margin-top: 10%;
  }
 
}
/* Media query for mobile devices */
@media (max-width: 768px) {
  .pauseImg {
    width: 25px; /* Adjust size for mobile */
    height: 25px; /* Adjust size for mobile */
  }

  .PrevNextDiv {
    gap: 10px; /* Adjust gap for mobile */
  }

  .infoDiv {
    font-size: 8px; /* Adjust font size for mobile */
    gap: 5px; /* Adjust gap for mobile */
  }
  .flaginfo Typography {
    display: none;

  }
  .emoticon-battery-container {
   top:0;
   right:10%;
    
  }
  .emotionImage {
    width:55px;
    height: 50px;
  }
  .batteryImg {
    width:45px;
    height: 50px;
  }
  .speakerDropDown {
    width: 100px;
  }
  .flagsDropDown {
    width: 100px;
  }
  .flagInfoText {
    display: none;
  }
}