/** @format */

.UploadComponentLayout {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
.UploadComponentChild {
  color: #2c2c2c;
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.2px;
  line-height: 24px;
  white-space: nowrap;
}
.UploadComponentCard {
  width: 80%;
  height: auto;
  align-items: center;
  margin-left: 10%;
  flex-shrink: 0;
  border: 1px solid #d4d4d4;
  background: #fff;
  box-shadow: -5px 2px 10px -3px rgba(0, 0, 0, 0.1);
  text-align: center;
  justify-content: center;
}
.UploadItemsWrap {
  width: 100%;
  align-items: center;
  margin-top: 5%;
  display: flex;

  gap: 20px;
  justify-content: center;
}
.MuiInputBase-root {
  border-radius: 5px !important;
}
.DropZone {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  /*border-radius: 8px;
border: 1px solid var(--selected-bg-stroke, #D4DFFF);
background: #F9F9F9;*/
}
.YoutubeComponentLayout {
  width: 100%;
  height: auto;
  display: block;
  align-items: center;
  justify-content: center;
}
.verifylink {
  font-family: 'Poppins-SemiBold', Helvetica;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #1206f9;
  text-decoration: none;
}
.LinkClear {
  width: 37px;
  height: 21px;
  color: #959595;

  text-decoration: none;
}
.YoutubeTitleDiv {
  width: 100%;
  height: auto;
  margin-top: 5%;
  align-items: center;
}
.previewDiv {
  width: 100%;
  height: auto;
  margin-top: 5%;
  display: flex;

  justify-content: center;
}
#VideoPreview {
  width: 60%;
  height: 200px;
  border-radius: 8px;
  border: 1px solid var(--selected-bg-stroke, #d4dfff);
  background: #f9f9f9;
  align-items: center;
}

.BtnDiv {
  width: 100%;
  height: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  gap: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.YoutubeSave {
  /* width: 221px;
  height: 51px; */
  flex-shrink: 0;
  border-radius: 80px !important;
  background: #1206f9;
}

.MuiInputBase-input {
  font-family: 'poppins' !important;
}
.MuiFormLabel-root {
  font-family: 'poppins' !important;
}

.upload-container {
  width: 80%;
  height: 80%;
  padding: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 2rem auto;
  border: 1px dashed rgba(black, 0.2);
  border-radius: 2rem;
  transition: 0.2s box-shadow, 0.2s background-color;
  box-shadow: 0 10px 40px -30px rgba(black, 0.2);
  &.over {
    background-color: #2669ff;
    box-shadow: 0 20px 60px -30px rgba(#2669ff, 1);
    h2 {
      color: white;
    }
  }
  h2 {
    transition: 0.2s color;
    text-align: center;
  }
}
