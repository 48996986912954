.group-wrapper {
    text-align: center;
    justify-content: center;
}
.success-imgSpan {
    margin-top: 30px;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
}