/** @format */

.recording {
  color: firebrick;
  display: inline-block;

  line-height: 1.5em;
}

.recording::before {
  content: '🔴';

  animation: recording 600ms alternate infinite;
}

@keyframes recording {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
}
