/** @format */

.subscription-layout {
  background: linear-gradient(
    180deg,
    rgba(206, 203, 255, 0.29) 0%,
    rgba(255, 255, 255, 0.34) 63.14%
  );
  width: 100%;
  height: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
