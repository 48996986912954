/** @format */

.clamped {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Adjust this number to control the number of lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: 'Poppins-SemiBold', Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  word-break: break-all; /* Add this property to allow breaking at underscores */
}

.dot {
  width: 5px;
  height: 5px;
  background-color: #606060;
  border-radius: 50%;
  display: inline-block;
}
.image-container {
  position: relative;
  display: inline-block;
}

.buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  border-radius: 5px;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  gap: 10px;
}

/* .buttons button {
  margin: 5px;
  padding: 5px 10px;
  border: none;

  cursor: pointer;
  border-radius: 3px;
} */
