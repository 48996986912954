/** @format */

.flex-container {
  display: flex;

  width: 100%;
}

.align-center {
  width: 80%; /* Push to the left end of the container */

  border-radius: 25px;
  margin-left: 25%;
}
.align-start {
  width: 20%;
}
