.SummaryDetailsDiv {
    margin: 10px;
    display: inline-flex;
    color: black;
    gap: 5%;
    width: 100%;
}
.score-text{
    color: var(--body-text, #33344B);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.176px;
}
.engagement-score {
    color: #1206F9;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.154px;
}
.engagementDiv{
    margin-top: 9%;
    width: 100%;
    gap: 10%;
}
.energyDiv{
    margin-top: 9%;
    width: 100%;
    gap: 10%;
}
.positivityDiv{
    margin-top: 9%;
    width: 100%;
    gap: 10%;
}
.variationDiv{
    margin-top: 9%;
    width: 100%;
    gap: 10%;
}
.SpeechDetailsDiv {
    margin: 10px;
   
    color: black;
    gap: 5%;
    width: 100%;
}
.OtherDetails {
    margin-top: 10px;
    display: inline-flex;
    color: black;
    gap: 10%;
    width: 100%;
    justify-content: space-between;
}
.overallEmotionImg {
    width: 50px;
    height: 50px;
}
.OverallEmotionDetailsDiv{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}